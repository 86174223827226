
.menu {
  background-color: #1b1c1d;
  width: 100%;
  min-width: 300px;
  position: fixed; 
  top: 0px; 
  height: 50px;
  z-index: 999;
}

.menubox{
  background-color: #1b1c1d;
  width: 100px;
  color: aliceblue;
  font-family:"华文楷体";
  text-align:center;
  display: inline-block;
}

.menuboxaudio{
  background-color: #1b1c1d;
  width: 400px;
  color: aliceblue;
  font-family:"华文楷体";
  text-align:center;
  display: inline-block;
}


.menutitle{
  font-family:"华文楷体";
  font-weight: bolder;
  font-size: large;
}

.drawerfont{
  color: #000;
}

.menuuserinfo {
  width: 200px;
  height: 50px;
  text-align:center;
  display: inline-block;
  font-family:"华文楷体";
  line-height: 50px;
  background-color: #1b1c1d;
  color: aliceblue;
}

.fontlink {
  color: aliceblue;
}




.bloglogo{
  background-color: #1b1c1d;
  margin-left: 10px;
  height: 50px;
  color: #00c6f1;
  font-family:"Helvetica";
  text-align:center;
  line-height: 50px;
  display: inline-block;
  font-weight: bolder;
}

.classposition{
  width: 200px;
  background: aliceblue;
  Padding:10px;
  text-align: center;
}

.atextforclass{
  color:aliceblue;
  cursor:pointer;
}

.specificclass{
  color: #000;
  font-size: medium;
  font-family: "华文楷体";
}


.searchbox{
  position: absolute;
  right: 0;
  background-color: #1b1c1d;
  width: 210px;
  /* height: 50px; */
  color: rgb(3, 18, 31);
  font-family:"华文楷体";
  text-align:center;
  display: inline-block;
  vertical-align: middle;
  line-height: 50px;
  right: 10px;
}

.fuzzysearchresult{
  background-color: #fff;
  text-align: left;
  max-height: 400px;
  overflow: auto;
}
.fuzzysearchresultunit{
  margin-left: 10px;
  cursor: pointer;
}

.hover{
  background: #000;
}


.helpitem {
  height: 50px;
  width: 50px;
}

.menuunfoldout{
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: rgb(20, 127, 221);
  display: inline-block;
  position: absolute;
  right: 20px;
  font-size:x-large;
}

.content{
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50px;
  background-image: url('../../../public/picture/pic5.jpg');
  min-height: 100%;
  background-repeat: repeat;
  background-size: 100%;
}

.userinfocard {
  width: 20%;
  background-color: white;
  display: inline-block;
  position: absolute;
  text-align: center;
  top: 0;
  border: 2px solid gray;

}

.avatar {
  width: 100%;
  position: relative;
}

.username {
  width: 100%;
  font-family:"Helvetica";
  font-size: large;
  font-weight: bolder;
  height: 40px;
  line-height: 40px;
}
.signature {
  width: 100%;
  font-family:"华文楷体";
  font-size: large;
  font-weight: bolder;
}



.articleinfocard {
  width: 60%;
  text-align: center;
  margin-left: 1%;
  margin-right: 1%;
  font-family:"华文楷体";
  transform: translateX(30%);
}


.singleblog{
  Padding: 30px;
  margin-top: 1px;
  animation: text-up 10s linear 0s 1 normal;
}


@keyframes text-up {0%{transform: translateY(10px);opacity:0.01;}1%{transform: translateY(10px);opacity:0.03;}2%{transform: translateY(9px);opacity:0.06;}3%{opacity:0.1;}4%{transform: translateY(9px);opacity:0.15;}5%{transform: translateY(8px);opacity:0.19;}6%{transform: translateY(8px);opacity:0.24;}7%{transform: translateY(7px);opacity:0.3;}8%{transform: translateY(7px);opacity:0.35;}9%{transform: translateY(6px);opacity:0.4;}10%{transform: translateY(5px);opacity:0.46;}11%{transform: translateY(5px);opacity:0.51;}12%{transform: translateY(4px);opacity:0.56;}13%{transform: translateY(4px);opacity:0.61;}14%{transform: translateY(3px);opacity:0.66;}15%{opacity:0.7;}16%{transform: translateY(3px);opacity:0.74;}17%{transform: translateY(2px);opacity:0.78;}18%{transform: translateY(2px);opacity:0.82;}19%{transform: translateY(1px);opacity:0.85;}20%{opacity:0.88;}21%{opacity:0.91;}22%{transform: translateY(1px);opacity:0.93;}23%{transform: translateY(0);opacity:0.96;}24%{opacity:0.98;}25%{opacity:0.99;}26%{opacity:1.01;}27%{opacity:1.02;}28%{opacity:1.03;}29%{opacity:1.04;}30%{transform: translateY(0);opacity:1.05;}31%{transform: translateY(-1px);opacity:1.05;}32%{opacity:1.06;}40%{opacity:1.06;}41%{transform: translateY(-1px);opacity:1.05;}42%{transform: translateY(0);}43%{opacity:1.05;}44%{opacity:1.04;}46%{opacity:1.04;}47%{opacity:1.03;}49%{opacity:1.03;}50%{opacity:1.02;}53%{opacity:1.02;}54%{opacity:1.01;}58%{opacity:1.01;}59%{opacity:1;}100%{transform: translateY(0);opacity:1;}}

.articletitle {
  
  width: 100%;
  font-size: large;
  font-weight: bolder;
  display: inline-block;
}

.articlecontent {
  font-size: small;
}

.butreadarti {
  text-align: center;
}

.lableinfocard {
  width: 20%;
  min-width: 100px;
  background-color: black;
  display: inline-block;
}

.pagenationofindex{
  text-align: center;
  display: inline-block;
  width: 100%;
}
.pagenationinput{
  width: 20px;
  display: inline-block;
}