
.albumplace {
  margin-top: 100px;
  margin-left: 10px;
  margin-right: 10px;
}

.albumitem {
  display: flex;
}

.albumitemobile{
}

.albumplacemobile {
  margin-top: 80px;
}

.albumname {
  color: black;
  font-family: "华文楷体";
  font-weight: bolder;
  font-size: larger;
}