.foot{
  width: 100%;
  background-color: black;
  
}

.articlepo{
  min-height: 100vh;
}


.afont{
  color: aliceblue;
  line-height: 40px;
}


