.blogposition{
  text-align: center;
}

.blogtitle {
  text-align: center;
}

.blogcontent{
  width: 60%;
  left: 20%;
  text-align: left;
  display: inline-block;
}


.blogbgcontent{
  top: 50px;
  position: absolute;
  width: 100%;
  text-align: center;
  background-image: url('../../../public/picture/pic5.jpg');
  min-height: 100%;
  background-repeat: repeat;
  background-size: 100%;
  font-family: "华文楷体";
  font-size: larger;
}

img{
  width:100%;
}

pre {
  background-color: #F5FAFA;
  padding: 10px 16px;
}

table
{
    border-collapse: collapse;
    margin: 0 auto;
    text-align: center;
}
table td, table th
{
    border: 1px solid #cad9ea;
    color: #666;
    height: 30px;
}
table thead th
{
    background-color: #CCE8EB;
    width: 100px;
}
table tr:nth-child(odd)
{
    background: #fff;
}
table tr:nth-child(even)
{
    background: #F5FAFA;
}

